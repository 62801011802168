import React from "react"
import { Link } from "gatsby"

import { withTextGrid } from "./withTextGrid"
import { Container } from "../../Styled/Container"
import { RichText } from "../../RichText/RichText"
import { Alignments, Columns, TextGridContent, Links, Row, Subtitle, Title, StyledPrimaryButton } from "./TextGridStyles"

interface Props {
  full?: boolean
  section: any
  Section: any
}

const TextGrid = withTextGrid(({ full = false, section, Section }: Props) => (
  <Section id={section.id} full={full} condensed={`true`}>
    <Container width={`lg`}>
      <Row alignment={section?.settings?.alignment} direction={section?.settings?.direction}>
        {section.blocks.map((block, i) => {
          const Column = Columns[block.width] ? Columns[block.width]() : Columns[`Full Width`]()
          const Alignment = Alignments[block.alignment] ? Alignments[block.alignment]() : Alignments[`Left`]()

          return (
            <Column key={i} alignment={block.alignment} index={i} headings={block?.title || block?.subtitle} display={block.displayDevice}>
              <Alignment>
                {block?.title ? (
                  <Title as={`div`} className={`rte`}>
                    <RichText content={block.title} />
                  </Title>
                ) : null}

                {block?.subtitle ? (
                  <Subtitle as={`div`} className={`rte`}>
                    <RichText content={block.subtitle} />
                  </Subtitle>
                ) : null}

                {block?.content ? (
                  <TextGridContent>
                    <RichText content={block.content} />
                  </TextGridContent>
                ) : null}

                {block?.links?.length > 0 ? (
                  <Links>
                    {block.links.map(link =>
                      link?.title && link?.link?.url ? (
                        link.link.type === `internal` ? (
                          <StyledPrimaryButton key={link.title} as={Link} to={link.link.url}>
                            {link.title}
                          </StyledPrimaryButton>
                        ) : (
                          <StyledPrimaryButton key={link.title} as={`a`} href={link.link.url} target={`_blank`}>
                            {link.title}
                          </StyledPrimaryButton>
                        )
                      ) : null
                    )}
                  </Links>
                ) : null}
              </Alignment>
            </Column>
          )
        })}
      </Row>
    </Container>
  </Section>
))

export default TextGrid
