import React, { lazy } from "react"

import { useCore } from "../../hooks/useCore"
import { useSection } from "../../hooks/useSection"

export const withSections = Component => ({ name = `Sections`, content, ...props }) => {
  const {
    helpers: { ErrorBoundary, isBrowser },
  } = useCore()
  const { transformSection } = useSection()

  const sections = content
    ?.filter(item => item)
    ?.map((item, index) => {
      const section = transformSection(item, index)
      const name = `${section.type?.charAt(0).toUpperCase()}${section.type?.slice(1)}`

      const SectionComponent = isBrowser ? lazy(() => import(`./${name}/${name}`)) : require(`./${name}/${name}`).default

      return {
        ...section,
        component: () => (
          <ErrorBoundary>
            <SectionComponent {...props} section={section} />
          </ErrorBoundary>
        ),
      }
    })

  Component.displayName = name
  return <Component {...props} content={sections} />
}
