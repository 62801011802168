import React from "react"

import { useImage } from "../../../hooks/useImage"
import { useRoutes } from "../../../hooks/useRoutes"

export const withArticlesItem = Component => ({ name = `ArticlesItem`, article, ratio, ratioSmall }) => {
  const { getFluidImage } = useImage()
  const { linkResolver } = useRoutes()

  const category = article?.categories?.[0]
  const title = article?.title
  const date =
    article?.publishedAt &&
    `${article.publishedAt.split(`-`).length > 1 ? `${article.publishedAt.split(`-`)[1]}.` : ``}${article.publishedAt.split(`-`)[0]}`
  const aspectRatio = ratio || 0.6666
  const aspectRatioMobile = ratioSmall || aspectRatio
  const image = getFluidImage(article.featuredImage)
  const url = linkResolver(article, `article`)?.url

  Component.displayName = name
  return (
    <Component
      aspectRatio={aspectRatio}
      aspectRatioMobile={aspectRatioMobile}
      category={category}
      date={date}
      image={image}
      title={title}
      url={url}
    />
  )
}
