import React from "react"
import { Link } from "gatsby"

import { withContentBlocks } from "./withContentBlocks"
import { Image } from "../../Image/Image"
import { Booking } from "../../Booking/Booking"
import { RichText } from "../../RichText/RichText"
import { Form } from "../../Form/Form"
import { P } from "../../Styled/Text"
import { Column, Grid, Links, StyledPrimaryButton, Subtitle, Title } from "./ContentBlocksStyles"

interface Props {
  blocks: Array<any>
  booking: any
  Container: any
  section: any
  Section: any
  setBooking: any
}

const ContentBlocks = withContentBlocks(({ blocks, booking, Container, section, Section, setBooking }: Props) => (
  <Section id={section.id}>
    <Container>
      <Grid>
        {blocks.map(block => (
          <Column key={block.key} alignment={block.alignment} sticky={block?.settings?.sticky} width={block.layout.width}>
            {block.type === `textBlockContent` ? (
              <>
                {block?.title && (
                  <Title>
                    <RichText content={block.title} />
                  </Title>
                )}

                {block?.subtitle && (
                  <Subtitle>
                    <RichText content={block.subtitle} />
                  </Subtitle>
                )}

                {block?.content && (
                  <P>
                    <RichText content={block.content} />
                  </P>
                )}

                {block?.links?.length > 0 && (
                  <Links>
                    {block.links
                      ?.filter(({ link, title }) => title && link?.url)
                      .map(link =>
                        link.link.type === `internal` ? (
                          <StyledPrimaryButton key={link.title} as={Link} to={link.link.url}>
                            {link.title}
                          </StyledPrimaryButton>
                        ) : link.link.url.includes(`calendly`) ? (
                          <StyledPrimaryButton onClick={() => setBooking(link.link.url)}>{link.title}</StyledPrimaryButton>
                        ) : (
                          <StyledPrimaryButton key={link.title} as={`a`} href={link.link.url} target={`_blank`}>
                            {link.title}
                          </StyledPrimaryButton>
                        )
                      )}
                  </Links>
                )}
              </>
            ) : block.type === `imageContent` ? (
              <Image src={block.image} />
            ) : (
              block.type === `contactFormContent` && (
                <Form
                  locales={block.locales}
                  subjects={block.subjects?.sort()?.map(subject => ({ label: subject, value: subject }))}
                  title={block.title}
                />
              )
            )}
          </Column>
        ))}
      </Grid>
    </Container>

    <Booking active={booking ? true : false} booking={booking} setActive={setBooking} />
  </Section>
))

export default ContentBlocks
