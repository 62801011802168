import React, { useState } from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useSanity } from "../../../hooks/useSanity"
import { Ratios } from "./ContentBlocksStyles"
import { Layouts, Containers } from "../SectionsStyles"

export const withContentBlocks = Component => ({ name = `ContentBlocks`, section: rawSection }) => {
  const [booking, setBooking] = useState(false)
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const { linkNormaliser, textNormaliser } = useSanity()

  const section = {
    ...rawSection,
    blocks: rawSection?.blocks?.map(block => ({
      ...block,
      key: block._key,
      type: block._type,
      content: textNormaliser(block?.content),
      subtitle: textNormaliser(block?.subtitle),
      title: textNormaliser(block?.title),
      links:
        block?.links?.length > 0
          ? block.links.map(link => ({
              link: linkNormaliser(link),
              title: textNormaliser(link.title),
            }))
          : [],
    })),
    type: rawSection._type,
    key: rawSection._key,
  }

  const blocks = section.blocks?.map(block => ({
    ...block,
    layout: block?.settings?.width && Ratios[block.settings.width] ? Ratios[block.settings.width] : Ratios[`Widescreen`],
  }))

  const Section = section?.layout?.bottomSpacing ? Layouts[section.layout.bottomSpacing] : Layouts["default"]
  const Container = section?.settings?.containerWidth ? Containers[section.settings.containerWidth] : Containers["default"]

  Component.displayName = name
  return blocks?.length > 0 ? (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...(section?.analytics || {}) })} partialVisibility={true}>
      <Component booking={booking} blocks={blocks} Container={Container} section={section} Section={Section} setBooking={setBooking} />
    </VisibilitySensor>
  ) : null
}
