import styled from "@emotion/styled/macro"
import tw from "twin.macro"

import { PrimaryButton } from "../../Styled/Button"
import { H4, H5 } from "../../Styled/Text"

export const Grid = tw.div`flex flex-wrap items-start justify-between -mx-0-6 -my-1-6 md:my-0-6`
export const Column = styled.div`
  ${tw`w-full px-0-6 py-1-6 md:py-0-6`}
  ${props => (props.sticky ? tw`md:sticky md:top-5` : ``)}
  ${props =>
    props.width === "1/2"
      ? tw`md:w-1/2`
      : props.width === "1/3"
      ? tw`md:w-1/3`
      : props.width === "1/4"
      ? tw`md:w-1/4`
      : props.width === "2/3"
      ? tw`md:w-2/3`
      : ``}
`
export const Title = tw(H5)`mb-1-6 md:mb-2-4 font-sans uppercase`
export const Subtitle = tw(H4)`mb-1-6 md:mb-4 font-sans uppercase`
export const Links = tw.div`mt-4`
export const StyledPrimaryButton = tw(PrimaryButton)`inline-block mb-1-2 min-w-29-3`

export const Ratios = {
  Widescreen: {
    width: "full",
  },
  Tall: {
    width: "1/2",
  },
  Short: {
    width: "1/2",
  },
  Mid: {
    width: "1/2",
  },
  "One Half": {
    width: "1/2",
  },
  "One Third": {
    width: "1/3",
  },
  "One Quarter": {
    width: "1/4",
  },
  "Two Thirds": {
    width: "2/3",
  },
}
