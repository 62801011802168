import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useSanity } from "../../../hooks/useSanity"
import { Layouts } from "../SectionsStyles"

export const withTextGrid = Component => ({ name = `TextGrid`, full, section: rawSection }) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const { linkNormaliser, textNormaliser } = useSanity()

  const section = {
    ...rawSection,
    blocks:
      rawSection.blocks?.map(block => ({
        ...block,
        type: block._type,
        content: textNormaliser(block?.content),
        subtitle: textNormaliser(block?.subtitle),
        title: textNormaliser(block?.title),
        links:
          block?.links?.length > 0
            ? block.links.map(link => ({
                link: linkNormaliser(link),
                title: textNormaliser(link.title),
              }))
            : [],
      })) || [],
  }

  const Section = section?.layout?.bottomSpacing ? Layouts[section.layout.bottomSpacing] : Layouts["default"]

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...(section?.analytics || {}) })} partialVisibility={true}>
      <Component full={full} section={section} Section={Section} />
    </VisibilitySensor>
  )
}
