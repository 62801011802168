import React, { useEffect, useState } from "react"

import { useForm } from "../../hooks/useForm"

export const withForm = Component => ({ name = `Form`, locales, subjects, title }) => {
  const fields = [
    {
      key: "name",
      initialValue: "",
      required: true,
      type: "text",
    },
    {
      key: "email",
      initialValue: "",
      required: true,
      type: "email",
    },
    {
      key: "subject",
      initialValue: "",
      required: true,
      type: "select",
    },
    {
      key: "message",
      initialValue: "",
      required: true,
      type: "textarea",
      placeholder: "message",
    },
  ]

  const [sent, setSent] = useState(false)

  const { getForm, submitForm, data, setData, loading, errors } = useForm()

  const form = getForm("contact")
  const type = data?.type ? form?.types[data?.type?.toLowerCase()] : Object?.values(form?.types)[0]

  const handleSubmit = async event => {
    event.preventDefault()
    await submitForm(form, data)
    setSent(true)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  useEffect(() => {
    setData(prevState => ({
      ...prevState,
      type: type?.title,
    }))
  }, [])

  //Clean up subjects array
  function removePrefix(str) {
    return str.split("_").pop()
  }
  const stripPrefixFromObj = obj => Object.entries(obj).reduce((result, [key, t]) => ({ ...result, [key]: removePrefix(t) }), {})
  const subjectsWithoutPrefix = subjects.map(stripPrefixFromObj)

  Component.displayName = name
  return (
    <Component
      data={data}
      errors={errors}
      fields={fields}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      locales={locales}
      sent={sent}
      subjects={subjectsWithoutPrefix}
      title={title}
    />
  )
}
