import React from "react"
import { Link } from "gatsby"

import { withImageGallery } from "./withImageGallery"

import { Image } from "../../Image/Image"
import { P, H5 } from "../../Styled/Text"
import { BlockWrapper, Grid, ImageWrapper, Large, SecondaryImageWrapper, Small, Text } from "./ImageGalleryStyles"

interface Props {
  Container: any
  items: Array<any>
  section: any
  Section: any
}

const ImageGrid = withImageGallery(({ Container, items, section, Section }: Props) =>
  items?.length ? (
    <Section id={section.id}>
      <Container>
        <Grid>
          {items.map(item => (
            <BlockWrapper key={item._key} width={item.layout.width}>
              <ImageWrapper as={item?.link?.url ? Link : `div`} to={item?.link?.url ? item.link.url : null}>
                <Large>
                  <Image ratio={item.layout.ratio} src={item.image} alt={item.image?.alt} />
                </Large>
                <Small>
                  <Image ratio={item.layout.ratioSmall ? item.layout.ratioSmall : item.layout.ratio} src={item.image} />
                </Small>

                {item?.title || item?.content ? (
                  <Text colour={item.colour}>
                    {item?.title ? <H5>{item.title}</H5> : null}
                    {item?.content ? <P>{item.content}</P> : null}
                  </Text>
                ) : null}
              </ImageWrapper>

              {item?.secondary?.image ? (
                <SecondaryImageWrapper
                  as={item?.secondary?.link?.url ? Link : `div`}
                  to={item?.secondary?.link?.url ? item?.secondary?.link?.url : null}
                >
                  <Large>
                    <Image ratio={item.layout.secondary.ratio} src={item.secondary.image} />
                  </Large>

                  <Small>
                    <Image
                      ratio={item.layout.secondary.ratioSmall ? item.layout.secondary.ratioSmall : item.layout.secondary.ratio}
                      src={item.secondary.image}
                    />
                  </Small>

                  {item?.secondary?.title || item?.secondary?.content ? (
                    <Text colour={item.secondary.colour}>
                      {item?.secondary?.title ? <H5>{item.secondary.title}</H5> : null}
                      {item?.secondary?.content ? <P>{item.secondary.content}</P> : null}
                    </Text>
                  ) : null}
                </SecondaryImageWrapper>
              ) : null}
            </BlockWrapper>
          ))}
        </Grid>
      </Container>
    </Section>
  ) : null
)

export default ImageGrid
