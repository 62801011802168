import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useImage } from "../../../hooks/useImage"
import { useRoutes } from "../../../hooks/useRoutes"
import { useSanity } from "../../../hooks/useSanity"
import { Layouts, Containers } from "../SectionsStyles"
import { Ratios } from "./ImageGalleryStyles"

export const withImageGallery = Component => ({ name = `ImageGallery`, section: rawSection }) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const { getFluidImage } = useImage()
  const { linkResolver } = useRoutes()
  const { linkNormaliser, textNormaliser } = useSanity()

  const section = {
    ...rawSection,
    blocks: rawSection?.blocks?.map(block => ({
      ...block,
      content: textNormaliser(block.content),
      title: textNormaliser(block.title),
    })),
  }

  const Section = section?.layout?.bottomSpacing ? Layouts[section.layout.bottomSpacing] : Layouts["default"]
  const Container = section?.settings?.containerWidth ? Containers[section.settings.containerWidth] : Containers["default"]

  const imageBlocks = section?.blocks?.filter(block => block?.image?.asset) || []
  const images = []

  imageBlocks.map((block, i) => {
    if (
      block.layout === "Short" &&
      imageBlocks[i - 1] &&
      imageBlocks[i - 1].layout === "Short" &&
      ((imageBlocks[i - 2] && imageBlocks[i - 2].layout === "Tall") || (imageBlocks[i + 1] && imageBlocks[i + 1].layout === "Tall")) &&
      images.length &&
      images[images.length - 1]
    ) {
      images[images.length - 1].secondary = block
    } else {
      images.push(block)
    }

    return null
  })

  const items = images.map(item => {
    const layout = Ratios[item.layout] ? Ratios[item.layout] : Ratios[`Widescreen`]
    if (item.secondary) {
      layout.secondary = Ratios[item.secondary.layout] ? Ratios[item.secondary.layout] : Ratios[`Widescreen`]
    }

    return {
      ...item,
      image: getFluidImage(item.image),
      link: linkNormaliser(item.link),
      layout,
      secondary: item.secondary
        ? {
            ...item?.secondary,
            image: getFluidImage(item.secondary.image),
            link: linkNormaliser(item.secondary.link),
          }
        : null,
    }
  })

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...(section?.analytics || {}) })} partialVisibility={true}>
      <Component Container={Container} items={items} section={section} Section={Section} />
    </VisibilitySensor>
  )
}
