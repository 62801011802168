import React from "react"

import { withFeaturedArticles } from "./withFeaturedArticles"
import { Container } from "../../Styled/Container"
import { ArticleItem } from "../../Articles/Item/ArticleItem"
import { RichText } from "../../RichText/RichText"
import { Grid, Title } from "./FeaturedArticlesStyles"

interface Props {
  section: any
  Section: any
}

const FeaturedArticles = withFeaturedArticles(({ section, Section }: Props) => (
  <Section id={section.id}>
    <Container width={`lg`}>
      {section?.title ? (
        <Title as={`div`}>
          <RichText content={section.title} />
        </Title>
      ) : null}
      <Grid>
        {section?.blocks?.map(block => (
          <ArticleItem key={block.id} article={block} />
        ))}
      </Grid>
    </Container>
  </Section>
))

export default FeaturedArticles
