import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useSanity } from "../../../hooks/useSanity"
import { Layouts } from "../SectionsStyles"

export const withFeaturedArticles = Component => ({ name = `FeaturedArticles`, section: rawSection }) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const { rawContentNormaliser, richTextNormaliser, textNormaliser } = useSanity()

  const section = {
    ...rawSection,
    title: textNormaliser(rawSection?.title),
    titleText: textNormaliser(rawSection?.title),
    blocks: rawSection?.blocks?.map(article => ({
      ...article,
      type: "article",
      content: rawContentNormaliser(article),
      title: textNormaliser(article.title),
      titleText: richTextNormaliser(article.title),
      categories: article?.categories?.length > 0 ? article.categories.map(category => category.title) : [],
    })),
  }

  const Section = section?.layout?.bottomSpacing ? Layouts[section.layout.bottomSpacing] : Layouts["default"]

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...(section?.analytics || {}) })} partialVisibility={true}>
      <Component section={section} Section={Section} />
    </VisibilitySensor>
  )
}
