import styled from "@emotion/styled/macro"
import tw from "twin.macro"

export const Grid = tw.div`flex flex-wrap items-start justify-between md:-m-0-6`
export const Text = styled.div`
  ${tw`absolute inset-0 p-1-6 w-full break-words`} ${props => (props.colour === "Light" ? tw`text-light` : tw`text-dark`)}
`
export const BlockWrapper = styled.div`
  ${tw`w-full py-0-6 md:px-0-6`} ${props =>
    props.width === "1/2" ? tw`md:w-1/2` : props.width === "1/3" ? tw`md:w-1/3` : props.width === "1/4" ? tw`w-1/2 md:w-1/4` : ``}
`
export const ImageWrapper = tw.div`relative block w-full`
export const SecondaryImageWrapper = tw.div`relative block w-full mt-1-2`
export const Large = styled.div`
  ${tw`hidden md:block`}
`
export const Small = styled.div`
  ${tw`block md:hidden`}
`

export const Ratios = {
  Widescreen: {
    ratio: 1.5,
    ratioSmall: "7 / 15",
    width: "full",
  },
  Tall: {
    ratio: 0.6,
    width: "1/2",
  },
  Short: {
    ratio: 1.2158,
    ratioSmall: 1,
    width: "1/2",
  },
  Mid: {
    ratio: 0.8571,
    ratioSmall: 0.7777,
    width: "1/2",
  },
  "One third": {
    ratio: 0.6666,
    width: "1/3",
  },
  "One quarter": {
    ratio: 0.6666,
    width: "1/4",
  },
}
