import React from "react"

import { withArticlesItem } from "./withArticlesItem"
import { Image } from "../../Image/Image"
import { RichText } from "../../RichText/RichText"
import { Card, ImageWrapper, Desktop, Mobile, StyledLink, StyledP, StyledH5, CategoryWrapper } from "../ArticleStyles"

interface Props {
  aspectRatio: string
  aspectRatioMobile: string
  category: string
  date: any
  image: string
  title: string
  url: string
}

export const ArticleItem = withArticlesItem(({ aspectRatio, aspectRatioMobile, category, date, image, title, url }: Props) => (
  <Card>
    <StyledLink to={url}>
      {image && (
        <ImageWrapper>
          <Desktop>
            <Image src={image} ratio={aspectRatio} />
          </Desktop>

          <Mobile>
            <Image src={image} ratio={aspectRatioMobile} />
          </Mobile>
        </ImageWrapper>
      )}

      <CategoryWrapper>
        {category ? <StyledP>{category}</StyledP> : <StyledP />}
        {date ? <StyledP>{date}</StyledP> : <StyledP />}
      </CategoryWrapper>

      {title && (
        <StyledH5>
          <RichText content={title} rte={false}>
            {title?.toString()}
          </RichText>
        </StyledH5>
      )}
    </StyledLink>
  </Card>
))
