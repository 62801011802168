import styled from "@emotion/styled/macro"
import tw from "twin.macro"

import { PrimaryButton } from "../../Styled/Button"
import { H4, H5 } from "../../Styled/Text"

export const Row = styled.div`
  ${tw`flex flex-wrap justify-center items-start`}
  ${props =>
    props.alignment === "Space Between"
      ? tw`justify-between`
      : props.alignment === "Space Around"
      ? tw`justify-around`
      : props.alignment === "Start"
      ? tw`justify-start`
      : props.alignment === "End"
      ? tw`justify-end`
      : tw`justify-center`}
  ${props => (props.direction === "Top to Bottom" ? tw`flex-col items-center` : ``)}
`
export const Title = tw(H5)`mb-1-6 md:mb-2-4 font-sans uppercase`
export const Subtitle = tw(H4)`mb-1-6 md:mb-4 font-sans uppercase max-w-58-8 mx-auto`
export const TextGridContent = tw.div`font-body text-sm`
export const Links = tw.div`mt-4`
export const StyledPrimaryButton = tw(PrimaryButton)`inline-block mb-1-2 min-w-29-3`

export const Columns = {
  "One Quarter": () =>
    styled.div`
      ${tw`px-0-6 w-full md:w-1/4`} ${props => (props.index ? (props.headings ? tw`mt-6-2 md:mt-0` : tw`mt-1-6 md:mt-0`) : ``)}
        ${props => (props.display === "Desktop Only" ? tw`hidden md:block` : props.display === "Mobile Only" ? tw`block md:hidden` : ``)}
    `,
  "One Half": () =>
    styled.div`
      ${tw`px-0-6 w-full md:w-1/2`} ${props => (props.index ? (props.headings ? tw`mt-6-2 md:mt-0` : tw`mt-1-6 md:mt-0`) : ``)}
        ${props => (props.display === "Desktop Only" ? tw`hidden md:block` : props.display === "Mobile Only" ? tw`block md:hidden` : ``)}
    `,
  "One Third": () =>
    styled.div`
      ${tw`px-0-6 w-full md:w-1/3`} ${props => (props.index ? (props.headings ? tw`mt-6-2 md:mt-0` : tw`mt-1-6 md:mt-0`) : ``)}
        ${props => (props.display === "Desktop Only" ? tw`hidden md:block` : props.display === "Mobile Only" ? tw`block md:hidden` : ``)}
    `,
  "Two Thirds": () =>
    styled.div`
      ${tw`px-0-6 w-full md:w-2/3`} ${props => (props.index ? (props.headings ? tw`mt-6-2 md:mt-0` : tw`mt-1-6 md:mt-0`) : ``)}
        ${props => (props.display === "Desktop Only" ? tw`hidden md:block` : props.display === "Mobile Only" ? tw`block md:hidden` : ``)}
    `,
  "Three Quarters": () =>
    styled.div`
      ${tw`px-0-6 w-full md:w-3/4`} ${props => (props.index ? (props.headings ? tw`mt-6-2 md:mt-0` : tw`mt-1-6 md:mt-0`) : ``)}
        ${props => (props.display === "Desktop Only" ? tw`hidden md:block` : props.display === "Mobile Only" ? tw`block md:hidden` : ``)}
    `,
  "Full Width": () =>
    styled.div`
      ${tw`px-0-6 w-full md:w-full`} ${props => (props.index ? (props.headings ? tw`mt-6-2 md:mt-0` : tw`mt-1-6 md:mt-0`) : ``)}
        ${props => (props.display === "Desktop Only" ? tw`hidden md:block` : props.display === "Mobile Only" ? tw`block md:hidden -mt-1 -mb-4` : ``)}
    `,
}

export const Alignments = {
  Left: () => tw.div`text-left`,
  Center: () => tw.div`text-center`,
  Right: () => tw.div`text-right`,
}
